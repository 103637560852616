import React from 'react'
import styled from "styled-components";
import Video from "./video";
import DescriptionVideo from "./descriptionvideo";
import PlusComponent from "../../ui/plusComponent";


const Content = styled.div`
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

`;

const Component = styled.div`
  margin-left: 25px;
`;

const ContainerDescriptionVideo = ({title, description, url}) => {
    return (
        <>
            <div style={{marginBottom: 76}}>
                <PlusComponent text="описание"/>
            </div>
            <Component>
                <Content>
                    <DescriptionVideo title={title} description={description} />
                    <Video url={url}/>
                </Content>
            </Component>
        </>
    )
}

export default ContainerDescriptionVideo
