import { useState, useEffect } from 'react';

export default function useViewport() {
    const [windowDimensions, setWindowDimensions] = useState({width: 1600, height: 500});
  
    useEffect(() => {
      if (typeof window === 'undefined') return;
      function handleResize() {
      let width = 1600;
        let height = 500;
        if(typeof window !== "undefined"){
          width = window.innerWidth;
          height = window.innerHeight;
        }
        setWindowDimensions({width, height});
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
}