import React from 'react'
import styled from "styled-components";

const Content = styled.div`
  color: white;
  max-width: 600px;
  margin-right: 50px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  @media(max-width: 900px){
    font-size: 14px;
  }
  @media(max-width: 500px){
    font-size: 12px;
    line-height: 22px;
    max-width: 100%;
  }
`;

const Name = styled.div`
  font-size: 22px;
  width:266px;
  line-height: 33px;
  color: #FFFFFF;
  margin-bottom: 50px;
  @media(max-width: 900px){
    font-size: 20px;
  }
  @media(max-width: 500px){
    font-size: 18px;
    line-height: 27px;
  }
`;

const DescriptionVideo = ({title, description}) => {

    return (
        <div>
            <Name>
              {title}
            </Name>
            <Content>
              {description}
            </Content>
        </div>
    )
}

export default DescriptionVideo
