import React from 'react';

const LeftArrow = props => {
    return (
        <svg width="36" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.067 0V10.6226H36V13.4743H10.2004V6.51141L4.1566 12.096L13.067 20.9601V25L0 12.0247L13.067 0Z" fill={ props.color || "white" } />
        </svg>
    )
}

export default LeftArrow;