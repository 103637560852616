import React, {useState} from "react";
import {Button} from "antd";
import styled from "styled-components";
import Play from '../../assets/Play.svg'
import Preview from '../../images/Preview.jpg'

const VideoTag = styled.iframe`
  width: 100%;
  border: 3px solid black;
  border-radius: 8px;
  height: 100%;
  background-color: black;
`;


const Container = styled.div`
  display: table;
  position: relative;
  width: 620px;
  height: 400px;
  border-radius: 8px;
  background-image: url(${Preview});
  background-color: rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    max-width: 330px;
    max-height: 186px;
    margin-top: 90px;
  }
  @media (max-width: 368px) {
    max-width: 200px;
    max-height: 146px;
    margin-top: 90px;
  }


`;

const PlayPause = styled(Button)`
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  @media (max-width: 968px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 568px) {
    width: 50px;
    height: 50px;
  }
`;

const NameVideo = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background-size: contain;
  background-position: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: auto auto 40%;
  @media (max-width: 968px) {
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 80%;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 40%;
  }
  @media (max-width: 368px) {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 50%;
  }
`;


const Video = ({url}) => {
    const [play, setPlay] = useState(false);
    const link = url.split('https://youtu.be/');

  return (


        <Container>
            <div style={play === false ? {width:'100%',height:'100%',opacity:0.7} : {width:'100%',height:'100%',opacity:1}}>
                <VideoTag
                          src={play === true ? "https://www.youtube.com/embed/" + link[1]: ''} allowfullscreen
                >
                </VideoTag>
            </div>
            {play === false ?
                (<>
                    <NameVideo>Шоурил квартиры</NameVideo>
                    <PlayPause type="primary" shape="circle" onClick={() => {
                        setPlay(true);
                    }}
                    >
                        <Play style={{marginLeft: 5}}/>
                    </PlayPause>
                </>)
                : (<div></div>)
            }
        </Container>
    )

}

export default Video;
